<template>
  <div class="settings d-flex">
    <v-navigation-drawer>
      <template #prepend>
        <v-list-item two-line>
          Настройки
        </v-list-item>
      </template>

      <v-divider />

      <v-list dense>
        <template v-for="(setting, idx) in settings">
          <v-list-item
            v-if="setting !== null"
            :key="setting.value"
            :class="isActive(setting)"
            @click="select(setting.value)"
          >
            <v-list-item-content class="pointer">
              <v-list-item-title>{{ setting.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-else
            :key="`divider-${idx}`"
            class="my-1"
          />
        </template>
      </v-list>
    </v-navigation-drawer>
    <div class="settings__body">
      <div
        v-show="!activeComponent"
        class="noComponent"
      >
        <p>
          Выберите справочник чтобы продолжить
        </p>
      </div>
      <component
        :is="activeComponent"
        :params="params"
        @perform="onPerform"
      />
    </div>
  </div>
</template>
<script>

export default {
  data:() => ({
    activeComponent:null,
    params:{
      item:null,
    },
    settings:[
      {
        name:'Основные',
        value:'main',
        component:() => import('@/components/settings/main.vue')
      }
    ],

  }),
  created(){
    this.openDefaultComponent()
  },
  methods:{
    isActive(item){
      return item.value === this.activeComponentName ? 'teal lighten-1': ''
    },
    openDefaultComponent(){
      const q = this.$route.query
      if(Object.keys(q).length && q?.list){
        this.select(q.list)
      }
    },
    select(code){
      const {component, value} = this.settings.find(i => i?.value === code)
      this.activeComponentName = value
      this.activeComponent = component ?? null
    },
    onPerform({component:code, item = null}){
      item ? this.params.item = item : this.params.item = null
      const {component} = this.forms.find(i => i.value === code)

      this.activeComponent = component ?? null
    }
  }
}
</script>
<style scoped>
    .pointer{
        cursor: pointer;
    }
  .settings{
    width:100%;
    min-height: calc(100vh - 129px)
  }
  .settings__body{
    width: 100%;
  }
  .noComponent{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
  }


</style>
